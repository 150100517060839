import type { PageTheme } from '@momenthouse/types'
import { setHighlight, useTheme } from '@momenthouse/ui'
import { CreatorProfileData, getArtistBrandProfile } from 'api/static'
import { getCreatorSEO } from 'constants/seo'
import GlobalLayout from 'layouts/GlobalLayout'
import { validateSlug } from 'libs/gsp'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'

const CreatorNav = dynamic(
  () => import('components/Common/CreatorNavigation'),
  { ssr: false }
)

const CreatorProfile = dynamic(() => import('modules/Artist/CreatorProfile'), {
  ssr: false,
})

interface PageProps {
  profileData: CreatorProfileData
  pageTheme: PageTheme
  updatedAt: string
}

const CreatorProfilePage = ({ profileData, pageTheme }: PageProps) => {
  const { query } = useRouter()
  const { setTheme, setPrimary, resetTheme } = useTheme()

  useEffect(() => {
    if (pageTheme) {
      // Set page primary color + theme
      setTheme('dark')
      setPrimary(pageTheme?.pagePrimaryColor)
      setHighlight(pageTheme?.pagePrimaryColor)
    }
    // Reset theme + color on unmount
    return () => resetTheme()
  }, [pageTheme])

  return (
    <>
      <CreatorNav creatorProfile={profileData} />
      <CreatorProfile profileData={profileData} />
    </>
  )
}

CreatorProfilePage.getLayout = function getLayout(page) {
  const profileData = page?.props?.profileData?.artistBrand
  // Custom ask for 'moment.co/tribulations'
  return (
    <>
      <GlobalLayout
        {...(profileData && { customSEO: getCreatorSEO(profileData) })}
      >
        {page}
      </GlobalLayout>
    </>
  )
}

export const getStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export async function getStaticProps({ params }) {
  const slug = params.artistBrandSlug as string
  const invalid = validateSlug(slug)
  if (invalid) return invalid

  try {
    const slug = params.artistBrandSlug as string
    const profileData = await getArtistBrandProfile(slug)
    /** See [Not Found](https://nextjs.org/docs/api-reference/data-fetching/get-static-props#notfound) * */
    if (profileData.error) {
      return {
        notFound: true,
        revalidate: 1,
      }
    }
    return {
      props: {
        profileData: profileData || {},
        updatedAt: new Date().toString(),
        pageTheme: {
          pagePrimaryColor: profileData?.artistBrand?.pagePrimaryColor || '',
        },
      },
      revalidate: false,
    }
  } catch (e) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    }
  }
}

export default CreatorProfilePage
