import { darken } from 'polished'

export default function setHighlight(highlight) {
  const resolved = highlight
  if (!resolved) return
  const d = document.documentElement
  const highlightTokens = {
    '--colors-highlight': resolved,
  }
  Object.entries(highlightTokens).forEach(([k, v]) => {
    d.style.setProperty(k, v)
  })
}
